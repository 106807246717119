<template>
	<div class="page" v-if="isShow">
		<div class="tbmg20">
		  <el-form :inline="true" :model="formObj" class="demo-form-inline">
		    <el-form-item label="优惠券状态">
		      <el-select v-model="formObj.canUse" placeholder="优惠券状态">
		        <!-- <el-option label="全部":value="null"/> -->
				<el-option label="可用优惠券":value="0"/>
				<el-option label="历史优惠券":value="1"/>
		      </el-select>
		    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="refreshSubmit">查询</el-button>
		    </el-form-item>
		  </el-form>
		</div>
		<div class="coupon_empty_box" v-if="dataList.length<=0">
			<img class="img" src="../assets/static/Group2242.png"/>
			<div class="text">暂时查询不到相关优惠券记录，可去<span @click="$util.routerPath('/couponCenter')">领券中心</span>看看吧！</div>
		</div>
		<!-- 内容 -->
		<div class="coupon_list_box">
			<div class="cpitem" v-for="(item,index) in dataList" :key="index">
				<div class="top_box">
					<div class="amout">￥<span>{{ item.amount }}</span></div>
					<div class="conbox">
						<div class="text1">满{{ item.minAmount }}减{{ item.amount }}元代金券</div>
						<div class="text2">优惠类型：
						     <span v-if="item.couponType==1">限品类券，仅限指定商品可用</span>
							 <span v-if="item.couponType==0">全品类券，全部商品可用</span>
						</div>
						<div class="text3">{{ item.startTime }}至{{ item.endTime }}</div>
					</div>
					<div class="line"></div>
					<div class="btndiv">
						<div class="lingqu" v-if="item.couponStatus==0" @click="$util.routerPath('/home')">使用</div>
					</div>
					<img v-if="item.couponStatus&&item.couponStatus==3" class="imgbg" src="../assets/static/yiguoqi.png"/>
					<img v-if="item.couponStatus&&item.couponStatus==1" class="imgbg" src="../assets/static/yishiyong.png"/>
				</div>
				<div class="coupon_sm">
					<div class="shuoming" @click="openbody(index)">
						<div class="bsnname">优惠券使用规则</div>
						<img v-if="item.ifbody" class="jiantou" src="../assets/static/jiantou_up.png"/>
						<img v-if="!item.ifbody" class="jiantou" src="../assets/static/jiantou_down.png"/>
					</div>
					<!-- <div class="sm_con" v-if="item.ifbody">
						<div>{{item.body}}</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="block20"></div>
		<div class="pagination_box_white" v-if="dataList.length>0">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	name: 'coupon',
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	data() {
		return {
			uid:"",
			formObj:{
				canUse:0,//是否可用 0可用 1历史
			},
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:1,
		};
	},
	mounted: function() {
		this.getdataPage()
	},
	computed: {
		...mapState(['phone', 'isLogin','bsnName','uuid']),
	},
	methods: {
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
		},
		//查询
		refreshSubmit(){
			this.currentPage = 1
			this.getdataPage()
		},
		//列表
		getdataPage() {
			var _this = this
			var params = this.formObj
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["userUuid"] = this.uuid
			this.$http.post('coupon/couponUser/getPage', params).then(function(res) {
				//console.log(res.data.records)
				//数据处理
				if (res.code == 200) {
					var records = res.data.records || res.data;
					_this.total =  res.data.total || 1;
					records.forEach((item,index)=>{
						item["ifbody"] = false //展开详细信息
						var date = item.startDate.split("T")
						if (date.length > 0) {
						  item["startTime"] = date[0].replace(/-/g, '.')
						}
						var date1 = item.endDate.split("T")
						if (date1.length > 0) {
						  item["endTime"] = date1[0].replace(/-/g, '.')
						}
					})
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//打开
		//展开信息
		openbody(index){
		    var body = this.dataList[index].body
		    this.$alert(body, '优惠券使用规则', {
		        confirmButtonText: '确定',
		    });
		},
		
	}
};
</script>
<style lang="scss" scoped>
.coupon_list_box{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	.cpitem{
		width: 308px;
		margin: 12px 5px 0;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		position: relative;
		border: 1px solid #E0E0E0;
		.top_box{
			width: 100%;
			height: 128px;
			display: flex;
			align-items: center;
			.amout{
				width: 88px;
				text-align: center;
				font-size: 12px;
				font-weight: 600;
				color: #FA6163;
				span{
					font-size: 23px;
				}
			}
			.conbox{
				width: 140px;
				min-height: 98px;
				display: flex;
				flex-direction: column;
				.text1{
					font-size: 12px;
					line-height: 15px;
					font-weight: 600;
					color: #333333;
				}
				.text2{
					margin-top: 10px;
					font-size: 12px;
					line-height: 17px;
					font-weight: 400;
					color: #666666;
				}
				.text3{
					margin-top: auto;
					font-size: 12px;
					font-weight: 400;
					color: #999999;
				}
			}
			.line{
				width: 0px;
				margin-left: 5px;
				height: 98px;
				border: 1px dashed #E0E0E0;
			}
			.btndiv{
				flex: 1;
				text-align: center;
				font-size: 13px;
				font-weight: bold;
				.lingqu{
					color: #1370FB;
					cursor: pointer;
				}
				.default_text{
					color: #999999;
				}
			}
		}
		.imgbg{
			width: 64px;
			height: 80px;
			position: absolute;
			z-index: 8;
			right: 0;
			top: 0;
		}
		.coupon_sm{
			width: 100%;
			position: relative;
			z-index: 8;
			border-radius: 4px 4px 4px 4px;
			border-top: 1px solid #f2f2f2;
			.shuoming {
				padding: 0 16px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				.bsnname {
					font-size: 12px;
					font-weight: 400;
					color: #999999;
				}
				.jiantou {
					width: 10px;
					height: 6px;
				}
			}
		}
	}
}
</style>
