<template>
	<div class="page" v-if="isShow">
		<div class="tbmg20">
		  <el-form :inline="true" :model="formObj" class="demo-form-inline">
		    <el-form-item label="订单编号">
		      <el-input v-model="formObj.ordersPno" type="text" placeholder="请输入" />
		    </el-form-item>
		    <el-form-item label="订单状态">
		      <el-select v-model="formObj.status" placeholder="订单状态">
		        <el-option
		          v-for="(item,index) in orderType"
		          :key="index"
		          :label="item.name"
		          :value="item.status"
		        />
		      </el-select>
		    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="refreshSubmit">查询</el-button>
		      <el-button @click="resetSubmit">重置</el-button>
		    </el-form-item>
		  </el-form>
		</div>
		<div class="table-panel">
			<div id="purchase-table" class="active">
				<div class="table">
					<div class="table-title">
						<div class="item" style="width: 52%;">订单详情</div>
						<div class="item" style="width: 16%;">金额</div>
						<div class="item" style="width: 16%;">订单状态</div>
						<div class="item" style="width: 16%;">操作</div>
					</div>
					<div class="table-container">
						<div class="item" v-for="(item,index) in dataList" :key="index">
							<div class="top-info">
								<div class="left-item">
									<img class="logo" src="https://yaozhongcheng.obs.cn-south-1.myhuaweicloud.com/logo.png"/>
									<div class="text"><span>{{bsnName}}</span></div>
								</div>
								<div class="left-item1">采购员：<span>{{item.purchaserUsername==item.spurchaserUsername?item.purchaserUsername:item.spurchaserUsername}}</span></div>
								<div class="left-item1">下单时间：<span>{{item.createDate}}</span></div>
								<div class="left-item1">订单编号：<span>{{item.ordersPno}}</span></div>
							</div>
							<div class="order-content">
								<div class="content active">
									<div class="commodity">
										<div class="img-list">
											<div class="imgdiv" v-for="(gitem,gindex) in item.medicineList" :key="gindex">
												<img class="img" :src="gitem.mainImages&&gitem.mainImages[0]"/>
											</div>
											<!-- <div class="imgdiv">
												<img class="img" src="../../assets/static/logo.png"/>
											</div> -->
										</div>
										<div class="commodity-info">
											<span v-for="(nitem,nindex) in item.medicineList" :key="nindex">{{nitem.goodsName}},</span>
											<!-- <span>防水透气型创可贴7厘米×1.8厘米×15片，1.8厘米×15片，</span> -->
										</div>
										<div class="total-info">
											<span>{{item.medicineList.length}}种</span>商品，共
											<span>{{item.goodsLength}}件</span>商品
										</div>
									</div>
									<div class="money">
										<span class="allprice" v-if="item.ordersType!=2">￥{{item.ordersPrice}}</span>
										<span class="allprice" v-if="item.ordersType==2">{{item.ordersPrice}}积分</span>
										<span class="discounts" v-if="item.ordersType!=2">订单优惠：-￥{{item.discountPrice}}</span>
										<span class="payType" v-if="item.payType=='MA'">小程序在线支付</span>
										<span class="payType" v-if="item.payType=='CASH'">货到付款</span>
										<span class="payType" v-if="item.payType=='POINT'">积分换购</span>
										<span class="payType" v-if="item.payType=='CORPORATE'">对公支付</span>
										<span class="payType" v-if="item.payType=='WEIXIN_NATIVE'">微信扫码</span>
										<span class="payType" v-if="item.payType=='UNIONPAY_NATIVE'">银联扫码</span>
										<span class="payType" v-if="item.payType=='ALIPAY_NATIVE'">支付宝扫码</span>
									</div>
									<div class="status">
										<span class="span" v-if="item.status==1">待付款</span>
										<span class="span" v-if="item.status==2">待发货</span>
										<span class="span" v-if="item.status==3">待收货</span>
										<span class="span" v-if="item.status==4">已完成</span>
										<span class="span" v-if="item.status==5">已退款</span>
										<span class="span" v-if="item.status==6">已关闭</span>
										<span class="span" v-if="item.status==7" @click="checkDetail(item.ordersPno)" style="color: #1370fb;line-height: 26px;cursor: pointer;text-decoration: underline;">请上传对公凭证</span>
										<span class="span" v-if="item.status==8">核实对公凭证中</span>
										<span class="span" v-if="item.status==9">ERP出库中</span>
										<span class="span" v-if="item.status==10">已发货</span>
										<span class="span" v-if="item.ordersStatus==3">(售后完成)</span>
									</div>
									<div class="handle">
										<span class="span" v-if="item.status>=2&&item.ordersStatus!=3" @click="checkDetail(item.ordersPno)">退款售后</span>
										<span class="span" @click="checkDetail(item.ordersPno)">订单详情</span>
										<span class="span" v-if="item.status==3&&item.ordersStatus!=3" @click="handleComfirm(item.ordersPno)">确认收货</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination_box_white">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
		<!-- 订单详情 -->
		<el-dialog title="订单详情" center width="68%" top="5vh" :visible.sync="isShowDetailForm" :close-on-click-modal="false">
			<div class="order_detail_box" v-if="order">
				<div class="type_box">
					<div class="line"></div>
					<div class="text">订单信息</div>
				</div>
				<div class="dataobj">
					<div class="item">
						<div class="text1">订单状态：</div>
						<div class="text2">
							<span v-if="order.status==1">待付款</span>
							<span v-if="order.status==2">待发货</span>
							<span v-if="order.status==3">待收货</span>
							<span v-if="order.status==4">已完成</span>
							<span v-if="order.ordersStatus==3">(售后完成)</span>
							<span v-if="order.status==5">已退款</span>
							<span v-if="order.status==6">已关闭</span>
							<span v-if="order.status==7">待上传对公凭证</span>
							<span v-if="order.status==8">核实对公凭证中</span>
							<span v-if="order.status==9">ERP出库中</span>
							<span v-if="order.status==10">已发货</span>
						</div>
					</div>
					<div class="item">
						<div class="text1">订单编号：</div>
						<div class="text2">{{orderNum}}</div>
					</div>
					<div class="item">
						<div class="text1">创建时间：</div>
						<div class="text2">{{order.createTime}}</div>
					</div>
					<div class="item">
						<div class="text1">支付方式：</div>
						<div class="text2">
							<span v-if="order.payType=='MA'">小程序在线支付</span>
							<span v-if="order.payType=='CASH'">货到付款</span>
							<span v-if="order.payType=='POINT'">积分换购</span>
							<span v-if="order.payType=='CORPORATE'">对公支付</span>
							<span v-if="order.payType=='WEIXIN_NATIVE'">微信扫码</span>
							<span v-if="order.payType=='UNIONPAY_NATIVE'">银联扫码</span>
							<span v-if="order.payType=='ALIPAY_NATIVE'">支付宝扫码</span>
						</div>
					</div>
					<div class="item">
						<div class="text1">实付款：</div>
						<div class="text3" v-if="order.ordersType!=2">￥{{order.ordersPrice}}</div>
						<div class="text3" v-if="order.ordersType==2">{{order.ordersPrice}}积分</div>
					</div>
					<div class="item">
						<div class="text1">订单备注：</div>
						<div class="text2">{{order.remarks || "无"}}</div>
					</div>
				</div>
				<div class="type_box" v-if="order.status==3 || order.status==4">
					<div class="line"></div>
					<div class="text">发货物流信息</div>
				</div>
				<div class="dataobj" v-if="order.status==3 || order.status==4">
					<div class="item" v-if="order.expressMsg">
						<div class="text1">物流名称：</div>
						<div class="text2">{{order.expressMsg.expressName || "待更新"}}</div>
					</div>
					<div class="item" v-if="order.expressMsg">
						<div class="text1">物流号码：</div>
						<div class="text2">{{order.expressMsg.expressNo || "待更新"}}</div>
					</div>
					<div class="item" v-if="order.expressMsg">
						<div class="text1">司机名称：</div>
						<div class="text2">{{order.expressMsg.driverName || "待更新"}}</div>
					</div>
					<div class="item" v-if="order.expressMsg">
						<div class="text1">司机号码：</div>
						<div class="text2">{{order.expressMsg.driverPhone || "待更新"}}</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">商品信息</div>
				</div>
				<div class="order_goods_box">
					<div class="type_box">
						<div class="text1">商品信息</div>
						<div class="text2">供应价</div>
						<div class="text3">数量</div>
						<div class="text4">操作</div>
					</div>
					<div class="goods_box">
						<div class="itemobj" v-for="(item,index) in order.drugsOrdersDetailList" :key="index">
							<div class="con_box">
								<div class="img"><img :src="item.medicineList.mainImages[0]" /></div>
								<div class="name_box">
									<div class="text1">{{item.medicineList.goodsName}}</div>
									<div class="text2">生产厂家：{{item.medicineList.manufacturerName}}</div>
									<div class="text3">规格：{{item.medicineList.baseSku}}</div>
									<div class="text3">批号：{{item.batchid}}</div>
								</div>
							</div>
							<div class="con_box1">
								<div class="text1" v-if="item.ordersType==2">{{item.medicineList.price}}积分</div>
								<div class="text1" v-else>￥{{item.medicineList.price}}</div>
							</div>
							<div class="con_box2">
								<div class="text1">{{item.goodsNum}}</div>
							</div>
							<div class="con_box3">
								<div class="text2" @click="tuikuanHandle(index)" v-if="item.goodsStatus==0">申请售后</div>
								<div class="text3" v-if="item.goodsStatus==1">申请售后中</div>
							</div>
						</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">收货信息</div>
				</div>
				<div class="dataobj">
					<div class="item">
						<div class="text1">收货地址：</div>
						<div class="text2">{{ order.address.province }}{{ order.address.city }}{{ order.address.area }}{{ order.address.address }}</div>
					</div>
					<div class="item">
						<div class="text1">收货单位：</div>
						<div class="text2">{{ order.address.company }}</div>
					</div>
					<div class="item">
						<div class="text1">联系电话：</div>
						<div class="text2">{{ order.address.phone }}</div>
					</div>
					<div class="item">
						<div class="text1">联系人：</div>
						<div class="text2">{{ order.address.name }}</div>
					</div>
				</div>
				<div class="type_box" v-if="order.payType=='CORPORATE'">
					<div class="line"></div>
					<div class="text">对公凭证证明</div>
				</div>
				<div class="dataobj image-list" v-if="order.payType=='CORPORATE'&&order.corporateList">
					<div v-for="(image, imgindex) in order.corporateList" :key="imgindex" class="image-wrap">
					    <el-image 
					       class="imgStyle"
					       :src="image" 
					       :preview-src-list="order.corporateList">
					     </el-image>
					</div>
					<div>
						<el-upload
						  class="upload-demo"
						  action="" :http-request="handlelistSuccess" :show-file-list="false" :before-upload="beforelistUpload"
						  multiple
						  :limit="2">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">最多上传2张，只能上传jpg/png文件，且不超过2m</div>
						</el-upload>
						<el-button size="small" type="success" v-if="corporateList.length>0" @click="uploadCorporateList">点击确认更新保存(已选择{{corporateList.length}}张)</el-button>
					</div>
				</div>
				<div class="footerbtn">
					<button class="default_btn" @click="isShowDetailForm = false">返 回</button>
					<!-- <button class="default_btn primary_btn">再次采购</button> -->
				</div>
			</div>
		</el-dialog>
		<!-- 申请售后 -->
		<el-dialog title="申请退款" center width="68%" top="5vh" :visible.sync="isShowRefundForm" :close-on-click-modal="false">
			<div class="order_detail_box" v-if="order">
				<div class="type_box">
					<div class="line"></div>
					<div class="text">商品信息</div>
				</div>
				<div class="order_goods_box">
					<div class="type_box">
						<div class="text1">商品信息</div>
						<div class="text2">供应价</div>
						<div class="text3">采购数量</div>
						<div class="text4">申请数量</div>
					</div>
					<div class="goods_box">
						<div class="itemobj" v-for="(item,index) in order.goodsList" :key="index">
							<div class="con_box">
								<div class="img"><img :src="item.medicineList.mainImages[0]" /></div>
								<div class="name_box">
									<div class="text1">{{item.medicineList.goodsName}}</div>
									<div class="text2">生产厂家：{{item.medicineList.manufacturerName}}</div>
									<div class="text3">规格：{{item.medicineList.baseSku}}</div>
								</div>
							</div>
							<div class="con_box1">
								<div class="text1" v-if="item.ordersType==2">{{item.medicineList.price}}积分</div>
								<div class="text1" v-else>￥{{item.medicineList.price}}</div>
							</div>
							<div class="con_box2">
								<div class="text1">{{item.goodsNum}}</div>
							</div>
							<div class="con_box3">
								<div class="text3">{{item.goodsNum}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">申请信息</div>
				</div>
				<el-form :model="refundObj" :rules="rules" label-width="120px">
					<el-form-item label="退款类型" prop="afterstar">
					    <el-select v-model="refundObj.refundDetail.refundType" placeholder="退款类型">
					      <el-option label="仅退款" :value="0"></el-option>
					      <el-option v-if="refundObj.status&&refundObj.status>2" label="退货退款" :value="1"></el-option>
					    </el-select>
					</el-form-item>
					<el-form-item label="退款原因" prop="afterstar">
					    <el-select v-model="refundObj.refundDetail.refundReason" placeholder="退款原因">
					      <el-option v-for="(item, index) in typelist1" :key="index" :label="item" :value="item" />
					    </el-select>
					</el-form-item>
					<el-form-item label="退款说明">
					    <el-input type="textarea" placeholder="退款说明" v-model="refundObj.refundDetail.refundExplain"></el-input>
					</el-form-item>
					<el-form-item label="图片证明">
					    <imageSetCom :list="imagesList" @removeimglist="removeimglist" @addimglist="addimglist"></imageSetCom>
					</el-form-item>
				</el-form>
				<div class="footerbtn">
					<button class="default_btn" @click="isShowRefundForm = false">返 回</button>
					<button class="default_btn primary_btn" @click="confimRefund">提交申请</button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import imageSetCom from "../../components/imagesSetting.vue"
export default {
	name: 'orderPage',
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	components:{
		imageSetCom
	},
	data() {
		return {
			orderType:[
				{
					name:"全部订单",
					status:0,
				},
				{
					name:"待付款",
					status:1
				},
				{
					name:"待发货",
					status:2
				},
				{
					name:"待收货",
					status:3
				},
				{
					name:"已完成",
					status:4
				},
				{
					name:"已退款",
					status:5
				},
				{
					name:"已关闭",
					status:6
				},
				{
					name:"待上传对公凭证",
					status:7
				},
				{
					name:"核实对公凭证中",
					status:8
				}
				
			],
			formObj:{
				ordersPno:'',
				status:0,//状态 0全部 1待付款 2代发货 3待收货 4已完成	
			},
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			isShowDetailForm:false,//详情弹窗
			orderNum:"",//订单详情
			order:null,//订单详情
			rules: {
				afterstar:[
					{required: true, message: '此项为必填项', trigger: 'blur' }
			    ]
			},
			isShowRefundForm:false,//售后申请弹窗
			refundObj:{
				ordersNo:"",
				status:0,
				refundDetail:{
					refundExplain:"",
					refundReason:"",
					refundType:0,//退款类型 0仅退款 1退货退款		false
					voucherUrl:[],
				}
			},
			imagesList: [],
			typelist1:["商品压损/质量问题","批号不符合","药品近效期","发货少货","供应商发错货","其他原因"],
			corporateList:[],//对公证明
		};
	},
	mounted: function() {
		this.handleDataList()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName','uuid','phone']),
	},
	methods: {
		//更新对公
		uploadCorporateList(){
			var _this = this
			var params = {}
			params["ordersPno"] = this.orderNum //父级订单号
			params["userUuid"] = this.uuid
			params["corporateList"] = this.corporateList
			this.$http.post('medicineOrders/order/uploadCorporateList', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.$message.success('更新成功!');
					_this.order.corporateList = _this.corporateList
					_this.corporateList = []
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		async handlelistSuccess({ file }) {
			var res = await this.$api.uploadHttp(file, {});
			if (res && res.status == 200) {
				if(this.corporateList.length>=2){
					this.$message.error("最多上传2张")
					return false;
				}else{
					this.corporateList.push(res.requestUrls[0])
					this.$message.success('上传图片成功!');
				}
			} else {
				this.$message.error('上传图失败!');
			}
		},
		beforelistUpload(file) {
			console.log(file);
			const isLt2M = file.size / 1024 / 1024 < 10;
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
				return false;
			}
			if (file.type == 'image/jpeg' || file.type == 'image/png') {
				console.log(this.BASEURL);
			} else {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
			}
		},
		// 多图删除
		removeimglist(index) {
		  this.imagesList.splice(index, 1)
		},
		// 多图增加
		addimglist(url) {
		  this.imagesList.push(url)
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.handleDataList()
		},
		//查询
		refreshSubmit(){
			this.currentPage = 1
			this.handleDataList()
		},
		//重置
		resetSubmit(){
			this.formObj.ordersPno = null
			this.formObj.status = 0
			this.currentPage = 1
			this.handleDataList()
		},
		handleDataList() {
			var _this = this
		    var params = this.formObj
		    params['currentPage'] = this.currentPage
		    params['pageSize'] = this.pageSize
			params["userUuid"] = this.uuid
		    this.$http.post('medicineOrders/order/getPage', params).then(function(res) {
		    	//数据处理
		    	if (res.code == 200) {
					_this.total = res.data.total
		    		var records = res.data.records
		    		//处理时间 ordersType	订单类型 0普通 1特价 2积分
		    		records.forEach((item,index)=>{
						console.log(index+'-1-'+item.status+item.payType+'--'+item.ordersStatus)
		    			//item["createTime"] = _this.$util.replaceT(item.createDate)
		    			item["discountPrice"] = Number(item.ordersOriPrice) - Number(item.ordersPrice)
		    			item["goodsLength"] = 0
		    			for(var i=0;i<item.medicineList.length;i++){
		    				item.goodsLength += item.medicineList[i].goodsNum
		    			}
						//如果支付方式为CORPORATE，增加订单流程
						item["aaaaa"] = 0
						if(item.ordersStatus==1 && item.payType=="CORPORATE"){
							console.log(item.corporateList.length)
							
							item.aaaaa=1
							/* if(item.corporateList.length==0){
								item.status=7
							} */
							if(item.corporateList.length>0){
								if(item.erpStatus<1){
									item.status=8
								}else{
									if(item.shipmenStatus<1){
										item.status=9
									}else{
										item.status=10
									}
								}
								
							}else{
								item.status=7
							}
						}
						
							//orders_status==1 && corporate_list==null   待支付凭证
							//orders_status==1 && corporate_list==true  等待核实订单
							//orders_status==1 && erp_status=1 备货中
							//shipmen_status=1 已发货
							/*  */
						console.log(index+'-2-'+item.status)
		    		})
		    		_this.dataList = records
					console.log(_this.dataList)
		    	} else {
		    		_this.$confirm(res.message, '提示', {
		    			confirmButtonText: '确定',
		    			cancelButtonText: '取消',
		    			type: 'warning'
		    		}).then(() => {}).catch(() => {});
		    	}
		    })
		},
		//查询详情
		checkDetail(orderNum){
			this.orderNum = orderNum
			this.getPNoOrder()
		},
		getPNoOrder() {
		    var _this = this
		    var params = {}
			params["ordersPno"] = this.orderNum //父级订单号
		    params["userUuid"] = this.uuid
		    // 发动请求
			this.$http.post('medicineOrders/order/PNoOrder', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					/// 赋值
					var dataobj = res.data
					dataobj["createTime"] = _this.$util.replaceT(dataobj.createDate)
					//活动优惠减免价格
					dataobj["discountPrice"] = Number(dataobj.ordersOriPrice) - Number(dataobj.ordersPrice)
					// dataobj["goodsLength"] = 0  //商品多少件
					// for(var i=0;i<dataobj.medicineList.length;i++){
					// 	dataobj.goodsLength += dataobj.medicineList[i].goodsNum
					// }
					if(dataobj.ordersStatus==1 && dataobj.payType=="CORPORATE"){
						
						
						if(dataobj.corporateList.length>0){
							if(dataobj.erpStatus<1){
								dataobj.status=8
							}else{
								if(dataobj.shipmenStatus<1){
									dataobj.status=9
								}else{
									dataobj.status=10
								}
							}
							
						}else{
							dataobj.status=7
						}
					}
					
					
					
					_this.order = dataobj
					_this.isShowDetailForm = true
					_this.isShowRefundForm = false
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//确认发货
		handleComfirm(ordersPno){
			var _this = this
			var params = {}
			params["ordersPno"] = ordersPno //订单号
			params["userUuid"] = this.uuid
			//发动请求
			this.$http.post('medicineOrders/order/confirmReceipt', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.$message.success("确认成功")
					_this.handleDataList()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//申请售后
		tuikuanHandle(index){
			var obj = this.order.drugsOrdersDetailList[index]
			this.order["goodsList"] = [obj]
			this.refundObj.ordersNo = obj.ordersNo
			this.refundObj.status = this.order.status
			this.isShowDetailForm = false
			this.isShowRefundForm = true
		},
		//提交申请
		confimRefund(){
			var _this = this
			if(!this.refundObj.refundDetail.refundReason){
				this.$message.error("请选择退款原因")
				return false;
			}
			var voucherUrl = this.imagesList
			var params = this.refundObj
			params['userUuid'] = this.uuid
			params.refundDetail.voucherUrl = voucherUrl
			//console.log(params)
			this.$http.post('medicineOrders/order/applyRefund', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.$alert('申请成功，请等待审核，如有任何疑问，可联系客服管理员！', '温馨提示', {
					    confirmButtonText: '确定',
					    callback: action => {
					      _this.isShowRefundForm = false
						 //刷新
						 _this.refundObj = {
						 	ordersNo:"",
						 	refundDetail:{
						 		refundExplain:"",
						 		refundReason:"",
						 		refundType:0,//退款类型 0仅退款 1退货退款		false
						 		voucherUrl:[],
						 	}
						 }
						 _this.imagesList = []
						 //_this.getPNoOrder()
						 _this.handleDataList()
					    }
					});
				}
			})
		}
	}
};
</script>
<style lang="scss" scoped>
.table-panel{
	width: 100%;
	.table-title{
		width: 100%;
		line-height: 48px;
		height: 48px;
		background: #ecf8ff;
		display: flex;
		font-size: 14px;
		border: 1px solid #ecf8ff;
		color: #878a90;
		font-weight: 700;
		.item{
			text-align: center;
		}
	}
	.table-container{
		width: 100%;
		margin-top: 10px;
		box-sizing: border-box;
		.item{
			border-bottom: 1px solid #DCDFE6;
			margin-bottom: 20px;
			.top-info{
				border: 1px solid #DCDFE6;
				// background: #ecf8ff;
				display: flex;
				padding: 10px 16px;
				align-items: center;
				flex-wrap: wrap;
				.left-item{
					display: flex;
					align-items: center;
					margin-right: 20px;
					.logo{
						width: 18px;
						height: 18px;
						border-radius: 50%;
						margin-right: 8px;
					}
					.text{
						color: #76838f;
						font-size: 14px;
						span{
							color: #333333;
						}
					}
				}
				.left-item1{
					margin-right: 20px;
					color: #76838f;
					font-size: 14px;
					span{
						color: #333333;
					}
				}
			}
			.order-content{
				.content{
					min-height: 160px;
					display: flex;
					border-left: 1px solid #DCDFE6;
					border-right: 1px solid #DCDFE6;
					font-size: 14px;
					align-items: center;
					&.active{
						align-items: center;
					}
				}
				.commodity{
					border-right: 1px solid #DCDFE6;
					padding: 10px 25px 0;
					width: 52%;
					overflow: hidden;
					min-height: 160px;
					box-sizing: inherit;
					.img-list{
						width: 100%;
						overflow: hidden;
						height: 114px;
						display: flex;
						align-items: center;
						.imgdiv{
							position: relative;
							width: 112px;
							height: 112px;
							overflow: hidden;
							display: block;
							margin-right: 10px;
							background-color: #f8f8f8;
						    .img{
								width: 100%;
								height: 100%;
							}
						}
					}
					.commodity-info{
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 12px;
						color: #76838f;
						padding-top: 6px;
					}
					.total-info{
						width: 100%;
						font-size: 12px;
						color: #76838f;
						padding-top: 6px;
						text-align: right;
						margin-bottom: 10px;
						span{
							color: #ff4c52;
						}
					}
				}
				.money{
					min-height: 160px;
					width: 16%;
					border-right: 1px solid #DCDFE6;
					color: #526069;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 20px;
					.allprice{
						font-weight: 600;
					}
					.discounts{
						color: #ff4c52;
					}
				}
				.status{
					min-height: 160px;
					width: 16%;
					border-right: 1px solid #DCDFE6;
					color: #526069;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 20px;
				}
				.handle{
					min-height: 160px;
					width: 16%;
					color: rgb(19, 112, 251);
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 26px;
					span{
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.order_detail_box{
	width: 100%;
	.type_box{
		height: 48px;
		display: flex;
		align-items: center;
		.line{
			width: 3px;
			height: 16px;
			background: #1370FB;
		}
		.text{
			margin-left: 12px;
			font-size: 15px;
			font-weight: 600;
			color: #333333;
		}
	}
	.dataobj{
		padding: 10px 10px;
		border: 1px solid #E0E0E0;
		.item{
			min-height: 28px;
			padding: 3px;
			display: flex;
			align-items: center;
			font-size: 14px;
			.text1{
				color: #999999;
				width: 82px;
				text-align: right;
			}
			.text2{
				color: #333333;
			}
			.text3{
				color: #FF2F2F;
			}
		}
	}
	.order_goods_box{
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #E0E0E0;
		.type_box{
			padding: 0 20px;
			height: 50px;
			display: flex;
			align-items: center;
			.text1{
				width: 51%;
				font-size: 16px;
				font-weight: 500;
				color: #666666;
			}
			.text2,.text3{
				width: 16%;
				font-size: 16px;
				font-weight: 500;
				color: #666666;
				text-align: center;
			}
			.text4{
				flex: 1;
				font-size: 16px;
				font-weight: 500;
				color: #666666;
				text-align: center;
			}
		}
		.goods_box{
			padding: 0 20px;
			.itemobj{
				width: 100%;
				display: flex;
				align-items: center;
				padding: 15px 0;
				border-top: 1px solid #E0E0E0;
				.con_box{
					width: 51%;
					display: flex;
					align-items: center;
					.img{
						width: 81px;
						height: 81px;
						background: #FAFAFA;
						img{
							width: 100%;
							height: 100%;
						}
					}
					.name_box{
						margin-left: 20px;
						flex: 1;
						.text1{
							font-size: 14px;
							font-weight: bold;
							color: #000000;
						}
						.text2,.text3,.text4{
							font-size: 14px;
							font-weight: 500;
							color: #666666;
						}
					}
				}
				.con_box1,.con_box2{
					width: 16%;
					.text1,.text2,.text3{
						font-size: 16px;
						font-weight: 500;
						color: #666666;
						text-align: center;
					}
				}
				.con_box3{
					flex: 1;
					.text1{
						font-size: 16px;
						font-weight: 600;
						color: #FF2F2F;
						text-align: center;
					}
					.text2{
						font-size: 16px;
						font-weight: 500;
						color: #666666;
						text-align: center;
						text-decoration: underline;
						cursor: pointer;
					}
					.text3{
						font-size: 16px;
						font-weight: 500;
						color: #666666;
						text-align: center;
					}
				}
			}
		}
	}
	.footerbtn{
		margin-top: 15px;
		.default_btn{
			display: inline-block;
			line-height: 1;
			white-space: nowrap;
			cursor: pointer;
			background: #FFF;
			border: 1px solid #DCDFE6;
			color: #606266;
			text-align: center;
			box-sizing: border-box;
			outline: 0;
			margin: 0;
			transition: .1s;
			font-weight: 500;
			padding: 12px 20px;
			font-size: 14px;
			border-radius: 4px;
			margin-right: 18px;
		}
		.primary_btn{
			color: #409EFF;
			background: #ecf5ff;
			border-color: #b3d8ff;
		}
	}
}
</style>
