<template>
	<div class="page">
		<!-- 顶部菜单 -->
		<topBar :curIndex="2"></topBar>
		<div class="block20"></div>
		<!-- 内容 -->
		<div class="yuexin_mine_box w1240 relative">
			<div class="home-left">
				<el-menu :collapse-transition="false" :default-active="curPath" @select="handleOpen" active-text-color="#1370FB">
					<el-menu-item index="userinfo">
						账号信息
					</el-menu-item>
					<el-menu-item index="order">
						采购订单
					</el-menu-item>
					<el-menu-item index="afterOrder">
						退款售后
					</el-menu-item>
					<el-menu-item index="groupOrder">
						拼团订单
					</el-menu-item>
					<el-menu-item index="collection">
						我的收藏
					</el-menu-item>
					<el-menu-item index="footprint">
						我的足迹
					</el-menu-item>
					<el-menu-item index="coupon">
						优惠券
					</el-menu-item>
				</el-menu>
			</div>
			<div class="home-right" v-if="isLogin">
				<div class="titlebox">
					<div class="text1">
						<span v-if="curPath=='userinfo'">账号信息</span>
						<span v-if="curPath=='order'">采购订单</span>
						<span v-if="curPath=='afterOrder'">退款售后</span>
						<span v-if="curPath=='groupOrder'">拼团订单</span>
						<span v-if="curPath=='coupon'">优惠券</span>
						<span v-if="curPath=='collection'">我的收藏</span>
						<span v-if="curPath=='footprint'">我的足迹</span>
					</div>
					<div class="text2" @click="handleOpen('outLogin')">退出登录</div>
				</div>
				<userInfo :isShow="curPath=='userinfo'?true:false"></userInfo>
				<couponCom :isShow="curPath=='coupon'?true:false"></couponCom>
				<orderPageCom :isShow="curPath=='order'?true:false"></orderPageCom>
				<afterOrderPage :isShow="curPath=='afterOrder'?true:false"></afterOrderPage>
				<groupOrderCom :isShow="curPath=='groupOrder'?true:false"></groupOrderCom>
				<collectionCom :isShow="curPath=='collection'?true:false"></collectionCom>
				<footprintCom :isShow="curPath=='footprint'?true:false"></footprintCom>
			</div>
		</div>
		<div class="block20"></div>
		<!-- 登录页面 -->
		<loginForm :isShow="isShowLoginForm" @loginFormSuccess="loginFormSuccess" @openzhuce="openzhuce" @handleLoginForm="handleLoginForm"></loginForm>
		<!-- 注册采购商弹窗 -->
		<registerForm :isShow="dialogFormRegister" @handlezhuceiForm="handlezhuceiForm"></registerForm>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import  topBar  from "../components/topBar/topBar.vue";
import  loginForm  from "../components/loginForm/loginForm.vue";
import  registerForm  from "../components/registerForm/registerForm.vue";
import  userInfo  from "./userinfo.vue";
import  couponCom  from "./coupon.vue";
import  orderPageCom  from "./order/orderPage.vue";
import  afterOrderPage  from "./order/afterOrderPage.vue";
import  groupOrderCom  from "./order/groupOrder.vue";
import  collectionCom  from "./order/collectionPage.vue";
import  footprintCom  from "./order/footprintPage.vue";
export default {
	components: {
		topBar,
		userInfo,
		loginForm,
		registerForm,
		couponCom,
		orderPageCom,
		afterOrderPage,
		groupOrderCom,
		collectionCom,
		footprintCom
	},
	data() {
		return {
			curPath:"userinfo",
			dialogFormRegister:false,//采购认证
			isShowLoginForm:false,//登录操作
		};
	},
	mounted: function() {
		var _this = this
		if(!this.isLogin){
			this.$alert('登录时限失效，请重新登录~', '温馨提示', {
			    confirmButtonText: '确定',
			    callback: action => {
			        // _this.$router.push({
			        // 	name: "home"
			        // });
					_this.handleLoginForm("open")
			    }
			});
		}else{
			if(this.$route.query&&this.$route.query.redirect){
				this.curPath = this.$route.query.redirect
			}
			//console.log(this.curPath)
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName']),
	},
	methods: {
		...mapMutations(['SET_Data','SET_ISLOGIN']),
		//左边栏目触发
		handleOpen(index) {
			var _this = this
			if(!this.isLogin){
				this.$alert('还未登录，请先登录~', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				        // _this.$router.push({
				        // 	name: "home"
				        // });
						_this.handleLoginForm("open")
				    }
				});
				return;
			}
		    console.log(index);
			if(index=="outLogin"){
				_this.$confirm("确认要退出登录吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.SET_Data({ key: 'sysUserInfo', value:{}})
					_this.SET_ISLOGIN(false)
					sessionStorage.setItem('token','');
					localStorage.setItem('loginForm','');
					sessionStorage.setItem('key_state','')
					_this.$router.push({
						path: "/home"
					});
				}).catch(() => {});
				return;
			}
			this.curPath = index
			this.$router.push({ path: '/mine', query: {redirect: index}})
		},
		//登录操作
		handleLoginForm(type){
			if(type=="open"){
				this.isShowLoginForm = true
			}else{
				this.isShowLoginForm = false
			}
		},
		openzhuce(){
			this.isShowLoginForm = false
			this.dialogFormRegister = true
		},
		//打开注册认证
		handlezhuceiForm(type){
			if(type=="open"){
				this.dialogFormRegister = true
			}else{
				this.dialogFormRegister = false
			}
		},
		//登录成功回调
		loginFormSuccess(){
			this.handleLoginForm("close")
		},
		
	}
};
</script>
<style lang="scss" scoped>
.yuexin_mine_box{
	height: 100%;
	display: flex;
	justify-content: space-between;
}
.home-left {
  width: 218px;
  // height: 90%;
  // overflow-y: scroll;
  background: #fff;
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.1);
}
.home-right{
	width: 998px;
	padding: 20px;
	display: block;
	background: #fff;
	box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.1);
	.titlebox{
		width: 100%;
		padding-bottom: 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.text1{
			font-size: 18px;
			font-weight: bold;
			color: #000000;
		}
		.text2{
			font-size: 16px;
			font-weight: 500;
			color: #000000;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
</style>
