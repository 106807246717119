<template>
  <div class="page" v-if="isShow">
	<div class="titleName">账号信息</div>
    <el-form :model="formObj" :rules="rules">
        <el-form-item label="用户名" :label-width="formLabelWidth" prop="afterstar">
           <el-input disabled v-model="formObj.username" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="我的账号" :label-width="formLabelWidth" prop="afterstar">
          <el-input disabled type="number" v-model="formObj.phone" placeholder="请输入"></el-input>
        </el-form-item>
    	<el-form-item label="用户昵称" :label-width="formLabelWidth" prop="afterstar">
    	  <el-input v-model="formObj.nickname" placeholder="请输入"></el-input>
    	</el-form-item>
    	<el-form-item label="用户头像" :label-width="formLabelWidth" prop="afterstar" v-if="!ifSon">
    	  <el-upload class="avatar-uploader" action="" :http-request="handleAvatarSuccess" :show-file-list="false" :before-upload="beforeAvatarUpload">
    	    <img v-if="formObj.picture" :src="formObj.picture" class="el-avatar">
    	    <i v-else class="el-icon-plus avatar-uploader-icon" />
    	    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
    	  </el-upload>
    	</el-form-item>
		<el-form-item label="用户密码" :label-width="formLabelWidth" v-if="!ifSon">
		  <div class="changetext" @click="showPassword=true">修改密码</div>
		</el-form-item>
		<el-form-item label="认证信息" :label-width="formLabelWidth" v-if="!ifSon">
		  <div class="changetext" @click="handlezhuceiForm('open')">点击修改</div>
		</el-form-item>
		<el-form-item label="首营交换平台" :label-width="formLabelWidth">
		  <div class="changetext" @click="$util.windowOpen('https://www.001pt.com/index1.html')">点击跳转</div>
		</el-form-item>
		<el-form-item label="首营交换操作指引" :label-width="formLabelWidth">
		  <div class="changetext" @click="$util.windowOpen('https://yaozhongcheng.obs.cn-south-1.myhuaweicloud.com:443/1653883530808.pdf')">点击查看</div>
		</el-form-item>
		<el-form-item label="本平台操作指引" :label-width="formLabelWidth">
		  <div class="changetext">点击查看</div>
		</el-form-item>
    	<!-- <el-form-item label="区域" :label-width="formLabelWidth" prop="afterstar">
    	    <div class="form_box">
    	    	<el-input disabled v-model="formObj.areaObj" placeholder="请输入详细地址"></el-input>
    	    </div>
    	</el-form-item>
    	<el-form-item label="营业地址" :label-width="formLabelWidth" prop="afterstar">
    	    <div class="form_box">
    			<el-input disabled v-model="formObj.address" placeholder="请输入详细地址"></el-input>
    	    </div>
    	</el-form-item>
    	<el-form-item label="收货信息" :label-width="formLabelWidth" prop="afterstar">
    	    <div class="form_box">
				<el-input disabled :value="formObj.receivingAddress.province+formObj.receivingAddress.city+formObj.receivingAddress.area" placeholder="请输入详细地址"></el-input>
				<div style="width: 100%;height: 8px;"></div>
    			<el-input disabled v-model="formObj.receivingAddress.address" placeholder="请输入详细地址"></el-input>
    			<div style="width: 100%;height: 8px;"></div>
    			<el-input v-model="formObj.receivingAddress.company" placeholder="请输入收货单位"></el-input>
    			<div style="width: 100%;height: 8px;"></div>
    			<el-input v-model="formObj.receivingAddress.name" placeholder="请输入收货人"></el-input>
    			<div style="width: 100%;height: 8px;"></div>
    			<el-input type="number" v-model="formObj.receivingAddress.phone" placeholder="请输入收货人电话"></el-input>
    	    </div>
    	</el-form-item> -->
    	<div class="dialog-footer flexrow flexcenter">
    	  <el-button type="primary" @click="submitForm">提 交 审 核</el-button>
    	</div>
    </el-form>
	<!-- 修改密码 -->
	<el-dialog title="修改密码" center width="50%" top="20vh" :visible.sync="showPassword" :close-on-click-modal="false">
	    <el-form :model="formObj" :rules="rules">
			<el-form-item label="新密码" :label-width="formLabelWidth" prop="afterstar">
			  <el-input v-model="formObj.password" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" :label-width="formLabelWidth" prop="afterstar">
			  <el-input v-model="formObj.confirm" placeholder="请输入"></el-input>
			</el-form-item>
			<div class="dialog-footer flexrow flexcenter">
			  <el-button type="primary" @click="submitForm">提 交 修 改</el-button>
			</div>
		</el-form>
	</el-dialog>
	<!-- 注册采购商弹窗 -->
	<registerForm :istype="'edit'" :isShow="dialogFormRegister" @handlezhuceiForm="handlezhuceiForm"></registerForm>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import  registerForm  from "../components/registerForm/registerForm.vue";
export default {
	name: 'userinfo',
	components:{
		registerForm
	},
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	computed: {
		...mapState(['sysUserInfo','userType','bsnName','uuid','nickName','avatarUrl','phone','isLogin','shareCode','oricode','ifSon']),
	},
    data() {
      return {
        formLabelWidth: '158px',
        formObj:{
			afterstar:true,
        	username:'',
        	nickname:"",
        	phone:"",
        	picture:'',
			password:"",
			confirm:"",
			receivingAddress:{},
        },
        rules: {
           afterstar: [{ required: true, message: '请填写', trigger: 'change' }]
        },
		showPassword:false,
		dialogFormRegister:false,//修改认证
      }
    },
  mounted: function() {
    // console.log(localStorage.getItem('user'));
    if (this.isLogin) {
       this.initUserinfo()
    }
   
  },
  methods: {
    //初始化用户信息
    initUserinfo(){
    	if(this.userType=="purchase"){
			console.log(this.sysUserInfo)
    		this.formObj.nickname = this.nickName
    		this.formObj.username = this.sysUserInfo.username
    		this.formObj.phone = this.phone
    		this.formObj.picture = this.avatarUrl
			this.formObj.address = this.sysUserInfo.info.address
			this.formObj.areaObj = this.sysUserInfo.info.areaObj
			this.formObj.receivingAddress = this.sysUserInfo.info.receivingAddress
    	}else{
    		this.formObj.nickname = this.nickName
    		this.formObj.username = this.sysUserInfo.username
    		this.formObj.phone = this.phone
    		this.formObj.picture = this.sysUserInfo.avatarUrl
    	}
    },
	//打开注册认证
	handlezhuceiForm(type){
		if(type=="open"){
			this.dialogFormRegister = true
		}else{
			this.dialogFormRegister = false
		}
	},
    submitForm() {
		var _this = this
        var params = this.formObj
        if(!params.nickname){
        	this.$message.error("请输入用户昵称")
        	return;
        }
        if(!params.phone){
        	this.$message.error("请输入用户手机号")
        	return;
        }
		if(this.userType=="purchase"){
			params["control"] = "medicineUser/Purchaser/editPurchaser"
		}else{
			params["control"] = "medicineUser/represent/editRepresent"
			params["receivingAddress"] = null
		}
		if(this.showPassword){
			if(!params.password){
				this.$message.error("请输入密码")
				return;
			}
			if(!params.confirm){
				this.$message.error("请再次输入确认密码")
				return;
			}
			if(params.confirm!=params.password){
				this.$message.error("两次密码不一致，请重新输入")
				return;
			}
		}else{
			params.password = ""
			params.confirm = ""
		}
		this.$http.post(params.control, params).then(function(res) {
			//数据处理
			if (res.code == 200) {
				_this.showPassword = false
				_this.$alert('提交成功，请等待审核结果或信息通知，切勿重复提交！', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				      
				    }
				});
			} else {
				_this.$confirm(res.message, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {}).catch(() => {});
			}
		})
    },
    async handleAvatarSuccess({ file }) {
        var res = await this.$api.uploadHttp(file, this.$ossClient)
        if (res && res.status == 200) {
          this.formObj.picture = res.requestUrls[0]
          this.$message.success('上传图片成功!')
        } else {
          this.$message.error('上传图失败!')
        }
    },
    beforeAvatarUpload(file) {
       // console.log(file)
       const isLt10M = file.size / 1024 / 1024 < 5
       if (!isLt10M) {
         this.$message.error('上传头像图片大小不能超过 5MB!')
         return false
       }
       if (file.type == 'image/jpeg' || file.type == 'image/png') {
         console.log(this.BASEURL)
       } else {
         this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
       }
       // alert()
    }
  }
}
</script>
<style lang="scss" scoped>
	.titleName{
		height: 40px;
		line-height: 40px;
		font-weight: 600;
		color: #1f2f3d;
		font-size: 16px
	}
	.changetext{
		text-align: left;
		cursor: pointer;
		font-size: 14px;
		color: #1370FB;
		padding-left: 18px;
		text-decoration: underline;
	}
</style>
