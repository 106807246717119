<template>
    <div class="page" v-if="isShow">
		<div class="xc_collection_box">
			<div class="item" v-for="(item,index) in dataList" :key="index">
				<div class="imgdiv"><img :src="item.skuImg"/></div>
				<div class="titlename clamp2">{{item.goodsName}}</div>
				<div class="taglist">
					<div class="tag" v-if="item.ordersType==0">商品</div>
					<div class="tag tag_red" v-if="item.ordersType==1">特价</div>
					<div class="tag tag_orange" v-if="item.ordersType==2">换购</div>
					<div class="tag tag_blue" v-if="item.ordersType==3">拼团</div>
				</div>
				<div class="btndiv">
					<div class="btn1 del_btn" @click="resetHandle(index)">删除</div>
					<div class="btn1 detail_btn" @click="navTodetail(index)">查看详情</div>
				</div>
			</div>
		</div>
		<div class="pagination_box_white">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
	name: 'collectionPage',
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	computed: {
		...mapState(['sysUserInfo','uuid','phone','isLogin']),
	},
    data() {
      return {
        dataList:[],//集合
		currentPage:1,
		pageSize:10,
		total:0,
      }
    },
  mounted: function() {
       this.getdataListPage()
  },
  methods: {
	  //跳转页面
	  handleCurrentChange(currentPage){
	  	console.log(currentPage)
	  	this.currentPage = currentPage
	  	this.getdataListPage()
	  },
	  //详情
	  navTodetail(index){
	  	// 0普通 1特价 2积分 3拼团
		var type = ""
	  	if(this.dataList[index].ordersType==0){
	  		type = "goods"
	  	}else if(this.dataList[index].ordersType==1){
			type = "tejia"
	  	}else if(this.dataList[index].ordersType==2){
			type = "exchange"
	  	}else if(this.dataList[index].ordersType==3){
	  		type = "team"
	  	}else{
			this.$message.error("此商品已下架！")
	  		return;
	  	}
	  	this.$router.push({ path: '/goodsDetail', query: {uid: this.dataList[index].goodsUuid,type:type,getby:"uuid" }})
	  },
	  //获取
	  getdataListPage() {
	  	var _this = this
	  	var params = {}
	  	params["currentPage"] = this.currentPage
	  	params["pageSize"] = 10
	  	params["userPhone"] = this.phone
	  	params["userUuid"] = this.uuid
		this.$http.post("medicine/medicineDrugsFootprint/getPage", params).then(function(res) {
			//数据处理
			if (res.code == 200) {
				var records = res.data.records
				_this.total = res.data.total
				_this.dataList = records
			}
		})
	  },
	  //删除
	  resetHandle(index){
	  	var _this = this;
	  	var params = {};
	  	params['userPhone'] = this.phone;
	  	params['userUuid'] = this.uuid;
	  	params['resetAll'] = false
	  	params['footprintIds'] = [this.dataList[index].id];
		this.$http.post("medicine/medicineDrugsFootprint/resetFootprint", params).then(function(res) {
			//数据处理
			if (res.code == 200) {
				_this.$message.success("删除成功！")
				_this.getdataListPage()
			}
		})
	  },
   
  }
}
</script>
<style lang="scss" scoped>
.xc_collection_box{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	.item{
		width: 301px;
		margin: 0 8px;
		height: 458px;
		padding: 21px;
		box-sizing: border-box;
		background: #FFFFFF;
		border: 1px solid #C4C4C4;
		display: flex;
		flex-direction: column;
		.imgdiv{
			width: 259px;
			height: 259px;
			background: #FAFAFA;
		}
		.titlename{
			width: 100%;
			font-size: 16px;
			font-weight: 500;
			color: #333333;
			line-height: 29px;
			margin-top: 8px;
		}
		.taglist{
			margin-top: 8px;
			display: flex;
			align-items: center;
			.tag{
				display: inline-block;
				width: 48px;
				height: 28px;
				background: #666666;
				border-radius: 3px;
				line-height: 28px;
				text-align: center;
				font-size: 13px;
				font-weight: 500;
				color: #FFFFFF;
				margin-right: 8px;
			}
			.tag_blue{
				background: #2A7FFF;
			}
			.tag_red{
				background: #FF2F2F;
			}
			.tag_orange{
				background: #FA6900;
			}
		}
		.btndiv{
			margin-top: auto;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.btn1{
				width: 104px;
				height: 41px;
				background: #999999;
				font-size: 14px;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 41px;
				text-align: center;
				border-radius: 5px;
				cursor: pointer;
			}
			.del_btn{
				background-color: #f56c6c;
			}
			.detail_btn{
				background-color:#1370FB;
			}
		}
	}
}
</style>
