<template>
	<div class="page" v-if="isShow">
		<div class="tbmg20">
		  <el-form :inline="true" :model="formObj" class="demo-form-inline">
		    <!-- <el-form-item label="订单编号">
		      <el-input v-model="formObj.ordersNo" type="text" placeholder="请输入" />
		    </el-form-item> -->
		    <el-form-item label="订单状态">
		      <el-select v-model="formObj.refundState" placeholder="订单状态">
				  <el-option
				    label="全部"
				    :value="null"
				  />
		        <el-option
		          v-for="(item,index) in orderType"
		          :key="index"
		          :label="item.name"
		          :value="item.status"
		        />
		      </el-select>
		    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="refreshSubmit">查询</el-button>
		      <el-button @click="resetSubmit">重置</el-button>
		    </el-form-item>
		  </el-form>
		</div>
		<div class="table-panel">
			<div id="purchase-table" class="active">
				<div class="table">
					<div class="table-title">
						<div class="item" style="width: 52%;">订单详情</div>
						<div class="item" style="width: 16%;">金额</div>
						<div class="item" style="width: 16%;">订单状态</div>
						<div class="item" style="width: 16%;">操作</div>
					</div>
					<div class="table-container">
						<div class="item" v-for="(item,index) in dataList" :key="index">
							<div class="top-info">
								<div class="left-item">
									<img class="logo" src="https://yaozhongcheng.obs.cn-south-1.myhuaweicloud.com/logo.png"/>
									<div class="text"><span>{{bsnName}}</span></div>
								</div>
								<div class="left-item1">退款类型：
								    <span v-if="item.refundDetail.refundType==0">仅退款</span>
								    <span v-if="item.refundDetail.refundType==1">退货退款</span>
								</div>
								<div class="left-item1">下单时间：<span>{{item.createDate}}</span></div>
								<div class="left-item1">售后订单编号：<span>{{item.ordersNo}}</span></div>
							</div>
							<div class="order-content">
								<div class="content active">
									<div class="commodity">
										<div class="img-list">
											<div class="imgdiv">
												<img class="img" :src="item.medicineList.mainImages[0]"/>
											</div>
										</div>
										<div class="commodity-info">
											<span>{{item.medicineList.goodsName}}</span>
											<!-- <span>防水透气型创可贴7厘米×1.8厘米×15片，1.8厘米×15片，</span> -->
										</div>
										<div class="total-info">
											申请数量：<span>1种</span>商品，共
											<span>{{item.goodsLength}}件</span>商品
										</div>
									</div>
									<div class="money">
										<span class="allprice" v-if="item.ordersType!=2">￥{{item.ordersPrice}}</span>
										<span class="allprice" v-if="item.ordersType==2">{{item.ordersPrice}}积分</span>
										<span class="payType" v-if="item.payType=='MA'">小程序在线支付</span>
										<span class="payType" v-if="item.payType=='CASH'">货到付款</span>
										<span class="payType" v-if="item.payType=='POINT'">积分换购</span>
										<span class="payType" v-if="item.payType=='CORPORATE'">对公支付</span>
										<span class="payType" v-if="item.payType=='WEIXIN_NATIVE'">微信扫码</span>
										<span class="payType" v-if="item.payType=='UNIONPAY_NATIVE'">银联扫码</span>
										<span class="payType" v-if="item.payType=='ALIPAY_NATIVE'">支付宝扫码</span>
									</div>
									<div class="status">
										<span class="span" v-if="item.refundFlag==1">待审核</span>
										<span class="span" v-if="item.refundFlag==2">待退货</span>
										<span class="span" v-if="item.refundFlag==3">已退货</span>
										<span class="span" v-if="item.refundFlag==4">已完成</span>
										<span class="span" v-if="item.refundFlag==5">已取消</span>
										<span class="span" v-if="item.refundFlag==6">已拒绝</span>
									</div>
									<div class="handle">
										<span class="span" @click="handleDetail(item.ordersPno)">订单详情</span>
										<span class="span" @click="handleafterDetail(item.ordersNo)">售后详情</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination_box_white">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
		<!-- 订单详情 -->
		<el-dialog title="订单详情" center width="68%" top="5vh" :visible.sync="isShowDetailForm" :close-on-click-modal="false">
			<div class="order_detail_box" v-if="order">
				<div class="type_box">
					<div class="line"></div>
					<div class="text">订单信息</div>
				</div>
				<div class="dataobj">
					<div class="item">
						<div class="text1">订单状态：</div>
						<div class="text2">
							<span v-if="order.status==1">待付款</span>
							<span v-if="order.status==2">待发货</span>
							<span v-if="order.status==3">待收货</span>
							<span v-if="order.status==4">已完成</span>
							<span v-if="order.status==5">订单详情可见</span>
							<span v-if="order.status==6">已取消</span>
						</div>
					</div>
					<div class="item">
						<div class="text1">订单编号：</div>
						<div class="text2">{{orderNum}}</div>
					</div>
					<div class="item">
						<div class="text1">创建时间：</div>
						<div class="text2">{{order.createDate}}</div>
					</div>
					<div class="item">
						<div class="text1">支付方式：</div>
						<div class="text2">
							<span v-if="order.payType=='MA'">小程序在线支付</span>
							<span v-if="order.payType=='CASH'">货到付款</span>
							<span v-if="order.payType=='POINT'">积分换购</span>
							<span v-if="order.payType=='CORPORATE'">对公支付</span>
							<span v-if="order.payType=='WEIXIN_NATIVE'">微信扫码</span>
							<span v-if="order.payType=='UNIONPAY_NATIVE'">银联扫码</span>
							<span v-if="order.payType=='ALIPAY_NATIVE'">支付宝扫码</span>
						</div>
					</div>
					<div class="item">
						<div class="text1">实付款：</div>
						<div class="text3" v-if="order.ordersType!=2">￥{{order.ordersPrice}}</div>
						<div class="text3" v-if="order.ordersType==2">{{order.ordersPrice}}积分</div>
					</div>
					<div class="item">
						<div class="text1">订单备注：</div>
						<div class="text2">{{order.remarks || "无"}}</div>
					</div>
				</div>
				<div class="type_box" v-if="order.status==3 || order.status==4">
					<div class="line"></div>
					<div class="text">发货物流信息</div>
				</div>
				<div class="dataobj" v-if="order.status==3 || order.status==4">
					<div class="item" v-if="order.expressMsg">
						<div class="text1">物流名称：</div>
						<div class="text2">{{order.expressMsg.expressName || "待更新"}}</div>
					</div>
					<div class="item" v-if="order.expressMsg">
						<div class="text1">物流号码：</div>
						<div class="text2">{{order.expressMsg.expressNo || "待更新"}}</div>
					</div>
					<div class="item" v-if="order.expressMsg">
						<div class="text1">司机名称：</div>
						<div class="text2">{{order.expressMsg.driverName || "待更新"}}</div>
					</div>
					<div class="item" v-if="order.expressMsg">
						<div class="text1">司机号码：</div>
						<div class="text2">{{order.expressMsg.driverPhone || "待更新"}}</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">商品信息</div>
				</div>
				<div class="order_goods_box">
					<div class="type_box">
						<div class="text1">商品信息</div>
						<div class="text2">供应价</div>
						<div class="text3">数量</div>
						<div class="text4">状态</div>
					</div>
					<div class="goods_box">
						<div class="itemobj" v-for="(item,index) in order.drugsOrdersDetailList" :key="index">
							<div class="con_box">
								<div class="img"><img :src="item.medicineList.mainImages[0]" /></div>
								<div class="name_box">
									<div class="text1">{{item.medicineList.goodsName}}</div>
									<div class="text2">生产厂家：{{item.medicineList.manufacturerName}}</div>
									<div class="text3">规格：{{item.medicineList.baseSku}}</div>
									<div class="text3">批号：{{item.batchid}}</div>
								</div>
							</div>
							<div class="con_box1">
								<div class="text1" v-if="item.ordersType==2">{{item.medicineList.price}}积分</div>
								<div class="text1" v-else>￥{{item.medicineList.price}}</div>
							</div>
							<div class="con_box2">
								<div class="text1">{{item.goodsNum}}</div>
							</div>
							<div class="con_box3">
								<div class="text3">申请售后中</div>
							</div>
						</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">收货信息</div>
				</div>
				<div class="dataobj">
					<div class="item">
						<div class="text1">收货地址：</div>
						<div class="text2">{{ order.address.province }}{{ order.address.city }}{{ order.address.area }}{{ order.address.address }}</div>
					</div>
					<div class="item">
						<div class="text1">收货单位：</div>
						<div class="text2">{{ order.address.company }}</div>
					</div>
					<div class="item">
						<div class="text1">联系电话：</div>
						<div class="text2">{{ order.address.phone }}</div>
					</div>
					<div class="item">
						<div class="text1">联系人：</div>
						<div class="text2">{{ order.address.name }}</div>
					</div>
				</div>
				<div class="footerbtn">
					<button class="default_btn" @click="isShowDetailForm = false">返 回</button>
					<!-- <button class="default_btn primary_btn">再次采购</button> -->
				</div>
			</div>
		</el-dialog>
		<!-- 售后订单详情 -->
		<el-dialog title="售后订单详情" center width="68%" top="5vh" :visible.sync="isShowRefundForm" :close-on-click-modal="false">
			<div class="order_detail_box" v-if="afterOrder">
				<div class="type_box">
					<div class="line"></div>
					<div class="text">订单信息</div>
				</div>
				<div class="dataobj">
					<div class="item">
						<div class="text1">订单状态：</div>
						<div class="text2">
							<span v-if="afterOrder.refundFlag==1">待审核</span>
							<span v-if="afterOrder.refundFlag==2">待退货</span>
							<span v-if="afterOrder.refundFlag==3">已退货</span>
							<span v-if="afterOrder.refundFlag==4">已完成</span>
							<span v-if="afterOrder.refundFlag==5">已取消</span>
							<span v-if="afterOrder.refundFlag==6">已拒绝</span>
						</div>
					</div>
					<div class="item">
						<div class="text1">订单编号：</div>
						<div class="text2">{{afterOrder.ordersNo}}</div>
					</div>
					<div class="item">
						<div class="text1">创建时间：</div>
						<div class="text2">{{afterOrder.createDate}}</div>
					</div>
					<div class="item">
						<div class="text1">退款类型：</div>
						<div class="text2" v-if="afterOrder.refundDetail">
							<span v-if="afterOrder.refundDetail.refundType==0">仅退款</span>
							<span v-if="afterOrder.refundDetail.refundType==1">退货退款</span>
						</div>
					</div>
					<div class="item">
						<div class="text1">退款原因：</div>
						<div class="text2" v-if="afterOrder.refundDetail">{{afterOrder.refundDetail.refundReason || "暂无"}}</div>
					</div>
					<div class="item">
						<div class="text1">退款说明：</div>
						<div class="text2" v-if="afterOrder.refundDetail">{{afterOrder.refundDetail.refundExplain || "暂无"}}</div>
					</div>
					<div class="item">
						<div class="text1">实付款：</div>
						<div class="text3" v-if="afterOrder.ordersType!=2">￥{{afterOrder.ordersPrice}}</div>
						<div class="text3" v-if="afterOrder.ordersType==2">{{afterOrder.ordersPrice}}积分</div>
					</div>
					<div class="item">
						<div class="text1">订单备注：</div>
						<div class="text2">{{afterOrder.remarks || "无"}}</div>
					</div>
				</div>
				<div class="type_box" v-if="afterOrder.refundFlag==2 || afterOrder.refundFlag==3 ||afterOrder.refundFlag==4">
					<div class="line"></div>
					<div class="text">退货物流信息</div>
				</div>
				<div class="dataobj" v-if="afterOrder.refundFlag==2 || afterOrder.refundFlag==3 ||afterOrder.refundFlag==4">
					<div class="item">
						<div class="text1">物流名称：</div>
						<el-input class="input" v-if="afterOrder.refundFlag==2" v-model="expressObj.refundMsg.expressName" placeholder="请输入内容，没有可填无"/>
						<div class="text2" v-if="afterOrder.refundFlag!=2">{{expressObj.refundMsg.expressName || "无填写"}}</div>
					</div>
					<div class="item">
						<div class="text1">物流号码：</div>
						<el-input class="input" v-if="afterOrder.refundFlag==2" v-model="expressObj.refundMsg.expressNo" placeholder="请输入内容，没有可填无"/>
						<div class="text2" v-if="afterOrder.refundFlag!=2">{{expressObj.refundMsg.expressNo || "无填写"}}</div>
					</div>
					<div class="item">
						<div class="text1">司机名称：</div>
						<el-input class="input" v-if="afterOrder.refundFlag==2" v-model="expressObj.refundMsg.driverName" placeholder="请输入内容，没有可填无"/>
						<div class="text2" v-if="afterOrder.refundFlag!=2">{{expressObj.refundMsg.driverName || "无填写"}}</div>
					</div>
					<div class="item">
						<div class="text1">司机号码：</div>
						<el-input class="input" v-if="afterOrder.refundFlag==2" type="number" v-model="expressObj.refundMsg.driverPhone" placeholder="请输入内容，没有可填无"/>
						<div class="text2" v-if="afterOrder.refundFlag!=2">{{expressObj.refundMsg.driverPhone || "无填写"}}</div>
					</div>
					<div class="item" v-if="afterOrder.refundFlag==2">
						<el-button  class="btn1" type="primary" plain @click="writeRefundGoods">确定提交</el-button>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">商品信息</div>
				</div>
				<div class="order_goods_box">
					<div class="type_box">
						<div class="text1">商品信息</div>
						<div class="text2">供应价</div>
						<div class="text3">数量</div>
						<div class="text4">状态</div>
					</div>
					<div class="goods_box">
						<div class="itemobj">
							<div class="con_box">
								<div class="img"><img :src="afterOrder.medicineList.mainImages[0]" /></div>
								<div class="name_box">
									<div class="text1">{{afterOrder.medicineList.goodsName}}</div>
									<div class="text2">生产厂家：{{afterOrder.medicineList.manufacturerName}}</div>
									<div class="text3">规格：{{afterOrder.medicineList.baseSku}}</div>
								</div>
							</div>
							<div class="con_box1">
								<div class="text1" v-if="afterOrder.ordersType==2">{{afterOrder.medicineList.price}}积分</div>
								<div class="text1" v-else>￥{{afterOrder.medicineList.price}}</div>
							</div>
							<div class="con_box2">
								<div class="text1">{{afterOrder.goodsNum}}</div>
							</div>
							<div class="con_box3">
								<div class="text3">售后处理状态</div>
							</div>
						</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">收货信息</div>
				</div>
				<div class="dataobj">
					<div class="item">
						<div class="text1">收货地址：</div>
						<div class="text2">{{ afterOrder.address.province }}{{ afterOrder.address.city }}{{ afterOrder.address.area }}{{ afterOrder.address.address }}</div>
					</div>
					<div class="item">
						<div class="text1">收货单位：</div>
						<div class="text2">{{ afterOrder.address.company }}</div>
					</div>
					<div class="item">
						<div class="text1">联系电话：</div>
						<div class="text2">{{ afterOrder.address.phone }}</div>
					</div>
					<div class="item">
						<div class="text1">联系人：</div>
						<div class="text2">{{ afterOrder.address.name }}</div>
					</div>
				</div>
				<div class="type_box">
					<div class="line"></div>
					<div class="text">售后图片证明</div>
				</div>
				<div class="dataobj image-list" v-if="afterOrder.refundDetail">
					<div v-for="(image, imgindex) in afterOrder.refundDetail.voucherUrl" :key="imgindex" class="image-wrap">
					    <el-image 
					       class="imgStyle"
					       :src="image" 
					       :preview-src-list="afterOrder.refundDetail.voucherUrl">
					     </el-image>
					</div>
				</div>
				<div class="footerbtn">
					<button class="default_btn" @click="isShowRefundForm = false">返 回</button>
					<button class="default_btn primary_btn" v-if="afterOrder.refundFlag==1" @click="handlecancelRefund(afterOrder.ordersNo)">取消申请</button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	name: 'afterOrderPage',
	props: {
		isShow: {
			type: Boolean,
			default: () => {
				return false;
			}
		},
	},
	data() {
		return {
			orderType:[
				{
					name:"待审核",
					status:1
				},
				{
					name:"退款售后中",
					status:2
				},
				{
					name:"已完成",
					status:3
				},
				{
					name:"已失效",
					status:4
				}
			],
			formObj:{
				ordersNo:'',
				refundState:null,//状态 null全部 退款状态 1待审核 2待退货(售后中) 3已完成 4已失效		
			},
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			isShowDetailForm:false,//详情弹窗
			orderNum:"",//订单详情
			order:null,//订单详情
			rules: {
				afterstar:[
					{required: true, message: '此项为必填项', trigger: 'blur' }
			    ]
			},
			imagesList: [],
			isShowRefundForm:false,//售后订单详情
			afterOrder:null,//售后订单详情
			expressObj:{
				ordersNo:"",
				refundMsg:{
					driverName:"",
					driverPhone:"",
					expressName:"",
					expressNo:"",
				}
			},
		};
	},
	mounted: function() {
		this.handleDataList()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName','uuid','phone']),
	},
	methods: {
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.handleDataList()
		},
		//查询
		refreshSubmit(){
			this.currentPage = 1
			this.handleDataList()
		},
		//重置
		resetSubmit(){
			this.formObj.refundState = null
			this.currentPage = 1
			this.handleDataList()
		},
		handleDataList() {
			var _this = this
		    var params = this.formObj
		    params['currentPage'] = this.currentPage
		    params['pageSize'] = this.pageSize
			params["userUuid"] = this.uuid
		    this.$http.post('medicineOrders/order/getRefundOrders', params).then(function(res) {
		    	//数据处理
		    	if (res.code == 200) {
					_this.total = res.data.total
		    		var records = res.data.records
		    		//处理时间 ordersType	订单类型 0普通 1特价 2积分
		    		records.forEach((item,index)=>{
		    			//item["createTime"] = _this.$util.replaceT(item.createDate)
		    			item["goodsLength"] = item.medicineList.goodsNum
		    		})
		    		_this.dataList = records
		    	} else {
		    		_this.$confirm(res.message, '提示', {
		    			confirmButtonText: '确定',
		    			cancelButtonText: '取消',
		    			type: 'warning'
		    		}).then(() => {}).catch(() => {});
		    	}
		    })
		},
		//订单详情
		handleDetail(orderNum){
			this.orderNum = orderNum
			this.getPNoOrder()
		},
		//售后详情
		handleafterDetail(ordersNo){
			var _this = this
			var params = {}
			params["ordersNo"] = ordersNo //子订单号
			params["userUuid"] = this.uuid
			// 发动请求
			this.$http.post('medicineOrders/order/NoOrder', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					/// 赋值
					var dataobj = res.data
					_this.afterOrder = dataobj
					_this.isShowRefundForm = true
					//查看退款售后记录，获取物流信息
					_this.getreadRefundMsg(ordersNo)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//订单详情
		getPNoOrder() {
		    var _this = this
		    var params = {}
			params["ordersPno"] = this.orderNum //父级订单号
		    params["userUuid"] = this.uuid
		    // 发动请求
			this.$http.post('medicineOrders/order/PNoOrder', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					/// 赋值
					var dataobj = res.data
					dataobj["createTime"] = _this.$util.replaceT(dataobj.createDate)
					//活动优惠减免价格
					dataobj["discountPrice"] = Number(dataobj.ordersOriPrice) - Number(dataobj.ordersPrice)
					// dataobj["goodsLength"] = 0  //商品多少件
					// for(var i=0;i<dataobj.medicineList.length;i++){
					// 	dataobj.goodsLength += dataobj.medicineList[i].goodsNum
					// }
					_this.order = dataobj
					_this.isShowDetailForm = true
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//填写退货物流
		writeRefundGoods(){
			var _this = this
			if(!this.expressObj.refundMsg.expressNo){
				this.$message.error("请填写物流单号，没有可填无！")
				return;
			}
			var params = this.expressObj
			params["userUuid"] = this.uuid
			params["ordersNo"] = this.afterOrder.ordersNo
			this.$http.post('medicineOrders/order/writeRefundGoods', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.$message.success("成功~")
					//刷新 	退款状态 0未发起 1待审核 2待退货 3已退货 4已完成 5已取消 6已拒绝
					_this.afterOrder.refundFlag = 3
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//取消申请退款
		handlecancelRefund(ordersNo){
			var _this = this
			this.$confirm('此订单退款售后申请处理中，确定要取消吗？', '温馨提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        var params = {
			        	userUuid:_this.uuid,
			        	ordersNo:ordersNo
			        };
					this.$http.post('medicineOrders/order/cancelRefund', params).then(function(res) {
						//数据处理
						if (res.code == 200) {
							_this.$message.success("成功~")
							_this.isShowRefundForm = false
							//刷新
							_this.handleDataList()
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
			    }).catch(() => {
			        this.$message({
			        type: 'info',
			        message: '已取消操作'
			    });          
			});
		},
		//查看退款售后记录，获取物流信息
		getreadRefundMsg(ordersNo){
			var _this = this
			var params = {}
			params["userUuid"] = this.uuid
			params["ordersNo"] = ordersNo
			this.$http.post('medicineOrders/order/readRefundMsg', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					//刷新 	退款状态 0未发起 1待审核 2待退货 3已退货 4已完成 5已取消 6已拒绝
					var reobj = res.data.find((item) => { //这里的options就是上面遍历的数据源
						return item.refundFlag == 3; //筛选出匹配数据
					});
					if(reobj){
						_this.expressObj.refundMsg = reobj.refundMsg
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
	}
};
</script>
<style lang="scss" scoped>
.table-panel{
	width: 100%;
	.table-title{
		width: 100%;
		line-height: 48px;
		height: 48px;
		background: #ecf8ff;
		display: flex;
		font-size: 14px;
		border: 1px solid #ecf8ff;
		color: #878a90;
		font-weight: 700;
		.item{
			text-align: center;
		}
	}
	.table-container{
		width: 100%;
		margin-top: 10px;
		box-sizing: border-box;
		.item{
			border-bottom: 1px solid #DCDFE6;
			margin-bottom: 20px;
			.top-info{
				border: 1px solid #DCDFE6;
				// background: #ecf8ff;
				display: flex;
				padding: 10px 16px;
				align-items: center;
				flex-wrap: wrap;
				.left-item{
					display: flex;
					align-items: center;
					margin-right: 20px;
					.logo{
						width: 18px;
						height: 18px;
						border-radius: 50%;
						margin-right: 8px;
					}
					.text{
						color: #76838f;
						font-size: 14px;
						span{
							color: #333333;
						}
					}
				}
				.left-item1{
					margin-right: 20px;
					color: #76838f;
					font-size: 14px;
					span{
						color: #333333;
					}
				}
			}
			.order-content{
				.content{
					min-height: 160px;
					display: flex;
					border-left: 1px solid #DCDFE6;
					border-right: 1px solid #DCDFE6;
					font-size: 14px;
					align-items: center;
					&.active{
						align-items: center;
					}
				}
				.commodity{
					border-right: 1px solid #DCDFE6;
					padding: 10px 25px 0;
					width: 52%;
					overflow: hidden;
					min-height: 160px;
					box-sizing: inherit;
					.img-list{
						width: 100%;
						overflow: hidden;
						height: 114px;
						display: flex;
						align-items: center;
						.imgdiv{
							position: relative;
							width: 112px;
							height: 112px;
							overflow: hidden;
							display: block;
							margin-right: 10px;
							background-color: #f8f8f8;
						    .img{
								width: 100%;
								height: 100%;
							}
						}
					}
					.commodity-info{
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 12px;
						color: #76838f;
						padding-top: 6px;
					}
					.total-info{
						width: 100%;
						font-size: 12px;
						color: #76838f;
						padding-top: 6px;
						text-align: right;
						margin-bottom: 10px;
						span{
							color: #ff4c52;
						}
					}
				}
				.money{
					min-height: 160px;
					width: 16%;
					border-right: 1px solid #DCDFE6;
					color: #526069;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 20px;
					.allprice{
						font-weight: 600;
					}
					.discounts{
						color: #ff4c52;
					}
				}
				.status{
					min-height: 160px;
					width: 16%;
					border-right: 1px solid #DCDFE6;
					color: #526069;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 20px;
				}
				.handle{
					min-height: 160px;
					width: 16%;
					color: rgb(19, 112, 251);
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 26px;
					span{
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.order_detail_box{
	width: 100%;
	.type_box{
		height: 48px;
		display: flex;
		align-items: center;
		.line{
			width: 3px;
			height: 16px;
			background: #1370FB;
		}
		.text{
			margin-left: 12px;
			font-size: 15px;
			font-weight: 600;
			color: #333333;
		}
	}
	.dataobj{
		padding: 10px 10px;
		border: 1px solid #E0E0E0;
		.item{
			min-height: 28px;
			padding: 3px;
			display: flex;
			align-items: center;
			font-size: 14px;
			.text1{
				color: #999999;
				width: 82px;
				text-align: right;
			}
			.text2{
				color: #333333;
			}
			.text3{
				color: #FF2F2F;
			}
			.input{
				width: 338px;
				height: 38px;
				border-radius: 4px;
				line-height: 38px;
				padding: 0 10px;
				box-sizing: border-box;
				font-size: 12px;
				font-weight: 400;
				color: #333333;
			}
			.btn1{
				margin-top: 5px;
			}
		}
	}
	.order_goods_box{
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #E0E0E0;
		.type_box{
			padding: 0 20px;
			height: 50px;
			display: flex;
			align-items: center;
			.text1{
				width: 51%;
				font-size: 16px;
				font-weight: 500;
				color: #666666;
			}
			.text2,.text3{
				width: 16%;
				font-size: 16px;
				font-weight: 500;
				color: #666666;
				text-align: center;
			}
			.text4{
				flex: 1;
				font-size: 16px;
				font-weight: 500;
				color: #666666;
				text-align: center;
			}
		}
		.goods_box{
			padding: 0 20px;
			.itemobj{
				width: 100%;
				display: flex;
				align-items: center;
				padding: 15px 0;
				border-top: 1px solid #E0E0E0;
				.con_box{
					width: 51%;
					display: flex;
					align-items: center;
					.img{
						width: 81px;
						height: 81px;
						background: #FAFAFA;
						img{
							width: 100%;
							height: 100%;
						}
					}
					.name_box{
						margin-left: 20px;
						flex: 1;
						.text1{
							font-size: 14px;
							font-weight: bold;
							color: #000000;
						}
						.text2,.text3,.text4{
							font-size: 14px;
							font-weight: 500;
							color: #666666;
						}
					}
				}
				.con_box1,.con_box2{
					width: 16%;
					.text1,.text2,.text3{
						font-size: 16px;
						font-weight: 500;
						color: #666666;
						text-align: center;
					}
				}
				.con_box3{
					flex: 1;
					.text1{
						font-size: 16px;
						font-weight: 600;
						color: #FF2F2F;
						text-align: center;
					}
					.text2{
						font-size: 16px;
						font-weight: 500;
						color: #666666;
						text-align: center;
						text-decoration: underline;
						cursor: pointer;
					}
					.text3{
						font-size: 16px;
						font-weight: 500;
						color: #666666;
						text-align: center;
					}
				}
			}
		}
	}
	.footerbtn{
		margin-top: 15px;
		.default_btn{
			display: inline-block;
			line-height: 1;
			white-space: nowrap;
			cursor: pointer;
			background: #FFF;
			border: 1px solid #DCDFE6;
			color: #606266;
			text-align: center;
			box-sizing: border-box;
			outline: 0;
			margin: 0;
			transition: .1s;
			font-weight: 500;
			padding: 12px 20px;
			font-size: 14px;
			border-radius: 4px;
			margin-right: 18px;
		}
		.primary_btn{
			color: #409EFF;
			background: #ecf5ff;
			border-color: #b3d8ff;
		}
	}
}
.image-list {
	display: flex;
	flex-wrap: wrap;
}
.image-list .image-wrap {
	position: relative;
	display: inline-block;
	box-sizing: content-box;
	margin: 0 8px;
	line-height: 0;
	vertical-align: top;
}
.image-list .imgStyle {
	width: 128px;
	height: 128px;
	border: 1px solid #d9d9d9;
	cursor: pointer;
}
</style>
